$dark-green: #124B4C;
$dark-green-alt: #124B4C;
$light-green: #6E9192;
$light-green-alt: #93ACAD;
$teal: #CBD7D8;
$muted-green: #E6ECED;
$orange: #CE976B;
$divider: #d4a98d;
$gf-required-orange: #BB6828;
$gf-border: #D1D0D1;
$gray: #606060;
$white: #FFFFFF;
$light-green-bg: #F3F5F6;
$light-green-bg-2: #E2E9EA;
$cultivation-link: #406E6F;
$button-green: #6E9192;

$font-stack: Montserrat, sans-serif;

// Define a map of breakpoints
$breakpoints: (
  'ultrawide': 2560px,
  'large': 1728px,
  'desktop': 1400px,
  'laptop': 1200px,
  'tablet-large': 992px,
  'tablet': 834px,
  'phablet': 768px,
  'phone-large': 576px,
  'phone': 430px
);

$breakpoints-min: (
  'ultrawide': 1729px,
  'large': 1401px,
  'desktop': 1201px,
  'laptop': 991px,
  'tablet-large': 835px,
  'tablet': 769px,
  'phablet': 577px,
  'phone-large': 431px
);

$theme-colors: (
  "primary": $dark-green,
  "secondary": $light-green
);

.modal {
  --bs-modal-margin: auto !important;
  /* Your desired value */
}

// Mixin to apply responsive styles
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if $breakpoint =='ultrawide' {
      @media screen and (min-width: map-get($breakpoints-min, $breakpoint)) {
        @content
      }
    }

    @else if $breakpoint =='phone' {
      @media screen and (max-width: map-get($breakpoints, 'phone')) {
        @content
      }
    }

    @else {
      @media screen and (min-width: map-get($breakpoints-min, $breakpoint)) and (max-width: map-get($breakpoints, $breakpoint)) {
        @content
      }
    }
  }

  @else {
    @warn "No such breakpoint defined in $breakpoints: #{$breakpoint}";
  }
}

// Deprecated mixin to apply responsive styles
$breakpoints-legacy: (
  'large': 1728px,
  'desktop': 1400px,
  'laptop': 1200px,
  'tablet-large': 992px,
  'tablet': 834px,
  'phablet': 768px,
  'phone-large': 576px,
  'phone': 430px
);

@mixin legacy-respond-to($breakpoint) {
  @if map-has-key($breakpoints-legacy, $breakpoint) {
    @media (max-width: map-get($breakpoints-legacy, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "No such breakpoint defined in $breakpoints: #{$breakpoint}";
  }
}



body {
  font-family: $font-stack !important;
  // background-color: $light-green-bg !important;
}

body a {
  color: $white;
  font-family: $font-stack !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-decoration: none;
}

a:visited {
  color: $white;
  font-family: $font-stack;
  //   // font-size: 16px;
  font-style: normal;
  //   // font-weight: 400;
  //   // line-height: 26px;
  text-decoration: none;
}

a:hover {
  color: $white;
  font-family: $font-stack;
  // // font-size: 16px;
  font-style: normal;
  // // font-weight: 400;
  // // line-height: 26px;
  text-decoration: none;
}

a:active {
  color: $white;
  font-family: $font-stack;
  // // font-size: 16px;
  font-style: normal;
  // // font-weight: 400;
  // // line-height: 26px;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

.bold {
  font-weight: bold !important;
}

header {
  background-color: $muted-green;
  font-family: $font-stack;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
  color: $dark-green;
}


header li a {
  color: $dark-green !important;
}

.ta-img-fluid {
  @include respond-to('ultrawide') {
    min-width: 100%;
    max-height: 354px;
  }

  @include respond-to('large') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('desktop') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('laptop') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('tablet-large') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('tablet') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('phablet') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('phone-large') {
    max-width: 100%;
    max-height: 354px;
  }

  @include respond-to('phone') {
    max-width: 100%;
    max-height: 354px;
  }
}


.ta-navbar-collapse {
  line-height: 1rem;
  height: 1rem;

}

.ta-navbar {
  max-width: 1600px;

  @include respond-to('ultrawide') {
    // margin-left: 4rem;
    // padding: 1.125rem 0 1.125rem 0 !important;
    // height: 6.25rem !important;
  }

  @include respond-to('large') {
    // margin-left: 4rem;
    // padding: 1.125rem 0 1.125rem 0 !important;
    // height: 6.25rem !important;
  }

  @include respond-to('desktop') {
    // padding: 1.125rem 0 1.125rem 0 !important;
    // margin-left: 4rem !important;
  }

  @include respond-to('laptop') {
    // padding: 1.375rem 0 1.375rem 0 !important;
    // margin-left: 0 !important;
  }

  @include respond-to('tablet-large') {
    // padding: 0.875rem 0 0.926rem 0 !important;
    // margin-left: 0 !important;
    // height: 4.375rem !important;


  }
}

.navbar-brand {
  @include respond-to('tablet-large') {
    padding: 0 !important;
  }
}

.navbar-brand {
  @include respond-to('large') {
    padding: 1.125rem 0 1.125rem !important;
  }
}

.header-logo {
  // max-width: 18.75rem;
  height: 64px;

  @include legacy-respond-to('laptop') {
    height: 39px;
  }

  // @include respond-to('ultrawide') {
  //   margin-right: 18.438rem !important;
  //   margin-left: 0 !important;
  //   vertical-align: middle;
  // }

  // @include respond-to('large') {
  //   margin-right: 2.265rem !important;
  //   margin-left: 0 !important;
  //   vertical-align: middle;
  // }

  // @include respond-to('desktop') {
  //   margin-right: 2.265rem !important;
  //   margin-left: 0 !important;
  // }

  // @include respond-to('laptop') {
  //   margin-left: 6.438rem !important;
  //   padding-bottom: 1.5rem;
  // }

  // @include respond-to('tablet-large') {
  //   margin-left: 0.75rem !important;
  //   width: 11.735rem;
  //   padding-bottom: 0.926rem;
  // }
}


.ta-nav-item {
  margin-left: 2rem;
}

.dropdown-menu {
  transition: 3s linear !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-toggler {
  border: none !important;
}

.ta-navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler-icon {
  width: 2.5rem !important;
}

.ta-nav-link-container {

  //@include respond-to('ultrawide') {
  //  padding-top: 2.25rem !important;
  //  box-sizing: content-box;
  //}
  //@include respond-to('large') {
  //  padding-top: 2.25rem !important;
  //}
  //@include respond-to('desktop') {
  //  padding-top: 2.25rem !important;
  //}
  &:hover .dropdown-menu {
    display: block;
  }
}

.dropdown-menu {
  font-size: 1rem !important;
  font-family: $font-stack !important;
  background-color: transparent !important;
  border: none !important;

  @include respond-to('ultrawide') {
    padding-top: 1.6rem !important;
  }

  @include respond-to('large') {
    padding-top: 1.6rem !important;
  }

  @include respond-to('desktop') {
    padding-top: 0.5rem !important;
  }

  @include respond-to('laptop') {
    padding-top: 0.5rem !important;
  }

}

.ta-sub-level {
  width: 14.188rem !important;
  border: 0.25px $white solid !important;
  padding: 12px 0 12px 1.5rem !important;
  background-color: $muted-green;
  cursor: pointer;
}

.dropdown-spacer {
  height: .25px;
  background: $white;
  width: 100%;
}

.ta-sub-level:hover {
  background-color: $dark-green;
  color: $white !important;
}

.ta-sub-level:hover a {
  color: $white !important;
}

.ta-top-level {
  border-radius: 0 !important;
  padding: 0 !important;
}

h2 {
  color: #606060;
  font-family: $font-stack;
  font-size: 40px;
  font-style: normal;
  font-weight: 440;
  line-height: normal;
}

h4 {

  /* Medium/H4 Heading */
  font-family: $font-stack;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h2 {
  color: #606060;
  font-family: $font-stack;
  font-size: 40px;
  font-style: normal;
  font-weight: 440;
  line-height: normal;
}

h4 {

  /* Medium/H4 Heading */
  font-family: $font-stack;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ta-stock-btn {
  color: $gray;
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 1.75rem;
  font-family: $font-stack !important;
}

.ta-stock-btn-mkt {
  font-weight: 600 !important;
}

.ta-stock-mobile {
  background: $dark-green !important;
  color: $white !important;
  font-size: 1rem !important;
  display: none !important;

  @include respond-to('tablet-large') {
    display: flex !important;
    height: 3.625rem;
    padding-top: 1.188rem;
  }
}

.ta-stock-mobile-lt {
  padding-left: 1.5rem !important;
}

.ta-stock-mobile-rt {
  padding-left: 2rem !important;
}

.ta-stock-btn-rt {
  margin-left: 0.875rem;

  @include respond-to('tablet-large') {
    display: none;
  }
}

.ta-stock-btn-lt {
  margin-left: 16px;

  @include respond-to('tablet-large') {
    display: none;
  }
}

.ta-stock-btn-lt-container {
  padding-left: 70px;
  display: flex;
  gap: 16px;
  align-items: center;
  min-width: 400px;
}

.ta-chevron-solid-dn {
  height: 1rem;
  width: 0.813rem;
  margin-left: 0.5rem;
}

.ta-circle-rt-link:hover {
  .ta-circle-arrow-rt {
    display: none;
  }

  .ta-circle-arrow-rt-alt {
    display: inline-block;
  }
}

.ta-circle-arrow-rt {
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
}

.ta-circle-arrow-rt-alt {
  height: 1.25rem;
  width: 1.25rem;
  display: none;
}

.menu-item-link {
  &:hover {
    color: $dark-green !important;
    text-decoration: underline;
  }
}

.menu-item-1 {
  @include respond-to('ultrawide') {
    padding-right: 1.5rem;
  }

  @include respond-to('large') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('desktop') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('laptop') {
    // padding-left: 6.438rem !important;
    padding-right: 1rem;
  }

  @include respond-to('tablet-large') {
    padding-left: 0 !important;
  }
}

.menu-item-2 {
  @include respond-to('ultrawide') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('large') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('desktop') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('laptop') {
    padding-right: 1.5rem !important;
  }
}

.menu-item-3 {
  @include respond-to('ultrawide') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('large') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('desktop') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('laptop') {
    padding-right: 1.5rem !important;
  }
}

.menu-item-4 {
  @include respond-to('ultrawide') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('large') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('desktop') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('laptop') {
    padding-right: 1.5rem !important;
  }
}

.menu-item-5 {
  @include respond-to('ultrawide') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('large') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('desktop') {
    padding-right: 1.5rem !important;
  }

  @include respond-to('laptop') {
    padding-right: 1rem !important;
  }
}

.menu-item-6 {}

footer {
  background-color: $dark-green;
  color: $white;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

footer li {
  list-style: none;
}

footer div.col {
  max-width: 20% !important;
  padding: 0 !important;
}

footer .ta-footer-content {
  width: 904px;
  display: flex;
  justify-content: space-between;

  @include legacy-respond-to('laptop') {
    width: 746px;
  }

  @include legacy-respond-to('tablet-large') {
    width: 746px;
  }

  @include legacy-respond-to('phablet') {
    width: 722px;
    max-width: 100vw;
    flex-direction: column;
    gap: 24px;
    text-align: center;
  }

  // @include legacy-respond-to('phone-large') {
  //   max-width: 100%;
  //   flex-direction: column;
  //   gap: 24px;
  //   text-align: center;
  // }

}

.ta-footer-logo {
  max-width: 236px;
  flex-shrink: 0;
  margin-bottom: 24px;
}

.ta-footer-email {
  font-weight: 500;
}

.ta-footer-email:hover {
  text-decoration: underline;
}

.ta-footer-subscribe-btn {
  margin-top: 48px;
  border-radius: 6px;
  width: 60%;
  font-weight: 600;
  color: $white;
  border: 1.5px $white solid;
  background-color: transparent;
  padding: 10px 16px;
}

.ta-social-media {
  max-width: 54px;
}

.ta-social-media-hover {
  display: none;
  max-width: 54px;
}

.ta-copyright-notice {
  margin-top: 48px !important;
  padding: 0 !important;
}

.brands-ribbon {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  background-color: $dark-green;
  padding: 28px 0;
  /* Adds some padding around the ribbon */
  flex-wrap: wrap;

  @include legacy-respond-to('desktop') {
    padding-left: 186px;
    padding-right: 186px;
  }

  @include legacy-respond-to('laptop') {
    padding-left: 116px;
    padding-right: 116px;
  }

  @include legacy-respond-to('tablet') {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include legacy-respond-to('phablet') {
    padding-left: 16px;
    padding-right: 16px;
  }

}

.brand img {
  transform: scale(0.9);
  display: block;
}

.home-content {
  position: relative;
  z-index: 0;

  p {
    margin-bottom: 0;
  }

  @include legacy-respond-to('laptop') {
    // padding-left: 116px;
    // padding-right: 116px;
  }

  @include legacy-respond-to('tablet') {
    // padding-left: 32px;
    // padding-right: 32px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e2e9ea;
  }

  .home-content-mask {
    background: rgba(230, 236, 237, 0.5); //linear-gradient(to top, rgba(230, 236, 237, 0), #E6ECED),
    display: grid;
    align-items: center;
    padding-top: 49px;
    gap: 32px;
    z-index: 0;
    padding-bottom: 49px;
  }
}

.home-content-bg {
  display: block;
  position: relative;
}

.home-content-bg::before {
  background: url("/wp-content/uploads/2024/02/Background-Cannabis-Image-2.png") no-repeat bottom center;
  /* background-position: bottom; */
  content: "";
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  height: calc(100vw / (2.5));
}

.about-us-button-container {
  padding-top: 32px;
}

.about-us-button {
  width: Hug (98px);
  height: Hug (38px);
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  gap: 8px;
  background-color: $light-green;
  color: $white;
  font-family: $font-stack;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border: 1.5px solid #6E9192;
}

.about-us-button:hover {
  background-color: #fff;
  color: #6E9192;
}

.about-us-section {
  font-family: var(--fontStack);
  color: var(--darkGreen);
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include legacy-respond-to('laptop') {
    grid-template-columns: auto;
  }

  h3 {
    font-family: $font-stack;
    font-size: 28px;
    line-height: 34.13px;
    font-weight: 440;
    margin-bottom: 8px;
    color: $gray;
  }

  p {
    font-family: $font-stack;
    color: $gray;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* margin-bottom: 20px;  */
  }


  .about-us-video-container {
    position: relative;
    display: grid;
    align-content: center;
    background-color: black;

    .about-us-video {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .about-us-video-modal-toggle {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      justify-content: center;
      align-items: center;
    }

    .about-us-video-modal-button {
      display: none;
      width: Hug (98px);
      height: Hug (38px);
      padding: 10px 16px 10px 16px;
      border-radius: 6px;
      gap: 8px;
      // background-color: $light-green;
      color: $white;
      font-family: $font-stack;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      border: 1.5px solid white;
      background: transparent;
    }

    .about-us-video-modal-button:hover {
      background-color: #fff;
      color: #6E9192;
    }
  }

  .about-us-video-container:hover {
    .about-us-video-modal-toggle {
      display: flex;
    }

    .about-us-video-modal-button {
      display: block;
    }
  }


  .content {
    background-color: white;
    padding: 32px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* height: 100%; */
    justify-content: start;
  }
}

.ta-modal-close {
  max-width: 834px;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  max-width: 834px;
}

.embed-responsive::before {
  display: block;
  content: "";
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin:auto;
}
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin:auto;
}

.button-primary {
  width: Hug (98px);
  height: Hug (38px);
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  gap: 8px;
  background-color: $light-green;
  color: $white;
  font-family: $font-stack;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border: 1.5px solid #6E9192;
}

.button-primary:hover {
  background-color: #fff;
  color: #6E9192;
}

.ta-card {
  img {
    max-width: 100%;
  }

  .card-title {
    font-family: $font-stack;
    font-size: 28px;
    line-height: 34.13px;
    font-weight: 440;
    margin-bottom: 8px;
    color: $gray;
    text-align: center;
    white-space: nowrap;

    @include legacy-respond-to('laptop') {
      font-size: 20px;
      line-height: 20px;
    }
  }

  p {
    font-family: $font-stack;
    color: $gray;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;

    @include legacy-respond-to('laptop') {
      text-align: left;
    }

    @include legacy-respond-to('phablet') {
      text-align: center;
    }
  }

  .ta-card-button-container {
    padding-top: 32px;
    display: flex;
    justify-content: center;
  }
}

.investor-card {
  @include legacy-respond-to('laptop') {
    display: flex;
    grid-column: span 2;
    flex-direction: row;
  }

  @include legacy-respond-to('phablet') {
    grid-column: span 1;
  }

  .card-title {
    text-align: center;

    @include legacy-respond-to('laptop') {
      text-align: start;
    }

    @include legacy-respond-to('phablet') {
      text-align: center;
    }
  }
}

.ta-investor-card-content {
  align-items: start;

  @include legacy-respond-to('laptop') {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include legacy-respond-to('phablet') {
    display: grid;
    grid-template-columns: 1fr;
  }

  .ta-card-button-container {
    @include legacy-respond-to('laptop') {
      justify-content: left;
    }

    @include legacy-respond-to('phablet') {
      justify-content: center;
    }
  }
}

.homepage-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include legacy-respond-to('laptop') {
    grid-template-columns: 1fr 1fr;
  }

  @include legacy-respond-to('phablet') {
    grid-template-columns: 1fr;
  }

  gap: 16px;
}

.locations-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 125px;
  background: rgba(96, 96, 96, .55);
  mix-blend-mode: normal;
  z-index: 10;

  @include legacy-respond-to('laptop') {
    height: 68px;
  }

  .locations-ribbon-container {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @include legacy-respond-to('phone-large') {
      max-width: 320px;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .state {
    color: $white;

    /* Semi-Bold/H4 Heading */
    font-family: $font-stack;
    font-size: 24px;
    font-style: normal;
    font-weight: 440;
    line-height: normal;

    @include legacy-respond-to('laptop') {
      font-size: 16px;
    }
  }

  .dot-separator {
    background: $white;
    height: 10px;
    width: 10px;

    @include legacy-respond-to('laptop') {
      height: 8px;
      width: 8px;
    }

    border-radius: 100%;
  }
}

.page-h1 {
  color: var(--default-ffffff-white, #FFF);
  text-align: center;

  /* Semi-Bold/H1 Heading */
  font-family: $font-stack;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;

  @include legacy-respond-to('phone-large') {
    font-size: 28px;
  }
}

.page-h3 {
  color: var(--default-ffffff-white, #FFF);
  text-align: center;

  /* Medium/H3 Heading */
  font-family: $font-stack;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;

  @include legacy-respond-to('phone-large') {
    font-size: 16px;
  }
}

.slider-button {
  min-width: 220px;
}

.light-green-bg {
  background: $light-green-bg;
}

.light-green-bg-2 {
  background: $light-green-bg-2;
}

.ribbon-header {
  color: var(--gray-606060800, #606060);

  font-family: $font-stack;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0;
}

.green-bg {
  background-color: #B4C5C6;
}

.video-container {
  display: grid;
  align-content: center;
  background-color: black;
  max-width: 1060px;

  .video {
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.cultivation-card-body {
  border: 1px solid #E2E9EA;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 32px;
  padding-bottom: 34px;

  @include legacy-respond-to('phablet') {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include legacy-respond-to('tablet') {
    padding-left: 8px;
    padding-right: 8px;
  }

}

.cultivation-links-section-title {
  color: #606060;

  /* Medium/H4 Heading */
  font-family: $font-stack;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
}

.cultivation-links-section-desc {
  color: #606060;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;
}

.cultivation-link-a {
  color: #406E6F;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cultivation-link-a:hover {
  color: #406E6F;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cultivation-link-a:visited {
  color: #406E6F;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cultivation-link-a:active {
  color: #406E6F;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cultivation-links {
  list-style: none;
  display: grid;
  gap: 12px;
  padding: 0;
}

.cultivation-links-divider {
  width: 219px;
  height: 0.5px;
  background: #D4A98D;
}

.about-top-content {
  color: #606060;

  p {
    /* Body/Base text */
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    margin: 0;
  }
}

.about-card {
  max-width: 648px;
}

.about-card-body {
  border: 1px solid #E2E9EA;
  background: #F3F5F6;
  padding-bottom: 32px;

  p {
    max-width: 512px;
    margin: auto;
  }

  text-align: left;

  @include legacy-respond-to('phone-large') {
    text-align: center;
  }
}

.about-card-header {
  position: relative;
}

.about-card-header-title {
  position: absolute;
  left: 0;
  top: calc(50% - 16px);
  width: 100%;
  color: white;
}

.about-metric {
  background: #BB6828;
  width: 192px;
  height: 134px;
  background-image: url('/wp-content/uploads/2024/04/MicrosoftTeams-image-5.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-metric-number {
  color: white;
  margin: 0;

  /* Semi-Bold/H2 Heading */
  font-family: $font-stack;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.about-metric-label {
  color: white !important;
  margin: 0 !important;

  /* Medium/H5 Heading */
  font-family: $font-stack !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.about-metric-sublabel {
  color: white !important;
  margin: 0 !important;
  text-align: center !important;
  /* Medium/H6 Heading */
  font-family: $font-stack !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.operational-footprint-title {
  padding-top: 32px;
  margin: 0;
  padding-bottom: 27px;
}

.sublabel-wrapper {
  min-height: 16px;
}

.copy-container {
  max-width: 1024px;
  margin: auto;

  @include legacy-respond-to('laptop') {
    max-width: 764px;
  }

  @include legacy-respond-to('tablet') {
    max-width: 660px;
  }

  @include legacy-respond-to('phone-large') {
    max-width: 380px;
  }
}

.our-mission-section {
  border-top: 0.5px solid #95AEAF;
  border-bottom: 0.5px solid #95AEAF;
}
.our-mission-title-img {
  max-height: 135px;
}
.our-mission-headline-img {
  max-height: 165px;
}
.our-values-title-img {
  max-height: 82px;
}

.our-vision-title-img {
  max-height: 249px; 
}

.care-value-card {
  width: 382px;

  @include legacy-respond-to('phablet') {
    width: 362px;
    padding-left: 8px;
    padding-right: 8px;
  }

  display: grid;
  gap: 16px;

  p {
    /* Body/Base text */
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    margin: 0;
  }
}

.core-value-img {
  width: 100px;
  height: 100px;
  margin: 0;
}

.core-values-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1276px;
  margin: auto;
  color: #606060;
}

.core-value-title {

  /* Semi-Bold/H5 Heading */
  font-family: $font-stack;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.leaders-section {
  padding-left: 16px;
  padding-right: 16px;

  @include legacy-respond-to('phone-large') {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include legacy-respond-to('laptop') {
    padding-left: 74px;
    padding-right: 74px;
  }

  display: flex;
  gap: 32px;
  justify-content: center;
  flex-wrap: wrap;
}

.leader-heading {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;

  h2 {
    color: #FFF;
    /* Semi-Bold/H2 Heading */
    font-family: $font-stack;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;

    @include legacy-respond-to('phablet') {
      font-size: 20px;
      line-height: normal;
    }
  }
}

.leader-img-container {
  padding: 34px;
}

.leader-headshot {
  width: 230px;
}

.leader-card {
  max-width: 620px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #D4A98D;
}

.leader-bio {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 42px;
  padding-right: 42px;
  background: #fff;
  // border: 0.5px solid #D4A98D;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  p {
    color: var(--gray-5-f-5-d-5-d-700, #5F5D5D);

    /* Body/Base text */
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
  }
}

.meet-leadership-link {
  color: #FFF;
  /* Semi-Bold/H6 Heading */
  font-size: 24px;
  font-family: $font-stack;
  font-style: normal;
  font-weight: 440;
  line-height: normal;

  img {
    height: 35px;
    width: 35px;

    @include legacy-respond-to('laptop') {
      height: 25px;
      width: 25px;
    }
  }

  @include legacy-respond-to('laptop') {
    font-size: 16px;
  }
}

.ta-dispensary-container {
  font-family: $font-stack !important;
  background-color: $light-green-bg;
  padding-bottom: 3rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ta-page-header {
  background-image: url('/wp-content/uploads/2024/02/Web-Dispensaries-Header-Image.png');
  color: #FFFFFF;
  // padding-bottom: 33px;
  min-height: 297px;
  padding-top: 6rem;

  @include respond-to('tablet-large') {
    //padding: 0 !important;
  }
}

.ta-dispensary-card-content {
  @include legacy-respond-to('laptop') {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.ta-dispensary-row-top {
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 3rem 0 0 0 !important;

  @include respond-to('ultrawide') {
    width: 84.375rem !important;
  }

  @include respond-to('large') {
    width: 84.375rem !important;
  }

  @include respond-to('desktop') {
    width: 60.563rem !important;
  }

  @include respond-to('laptop') {
    width: 60.563rem;
  }

  @include respond-to('tablet-large') {
    width: 48rem;
  }

  @include respond-to('tablet') {
    width: 48rem !important;
  }

  @include respond-to('phablet') {
    width: 47.5rem;
  }
}

.ta-dispensary-row2 {
  max-width: 1350px;
  border: 0.5px solid #E2E9EA;

  @include legacy-respond-to('desktop') {
    max-width: 968px;
  }

  @include legacy-respond-to('laptop') {
    flex-direction: column-reverse;

  }
}

.ta-dispensary-row2-right {
  @include legacy-respond-to('laptop') {
    flex-direction: column;
  }
}

.ta-dispensary-row {
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 2rem 0 0 0 !important;

  @include respond-to('ultrawide') {
    width: 84.375rem !important;
  }

  @include respond-to('large') {
    width: 84.375rem !important;
  }

  @include respond-to('desktop') {
    width: 60.563rem !important;
  }

  @include respond-to('laptop') {
    width: 60.563rem;
  }

  @include respond-to('tablet-large') {
    width: 48rem !important;
  }

  @include respond-to('tablet') {
    width: 48rem !important;
  }

  @include respond-to('phablet') {
    width: 47.5rem;
  }
}

.ta-dispensary-info-left {
  position: relative;
  border: 0.5px #E2E9EA solid;
  border-right: none;
  border-bottom: none;
  background-color: #FFFFFF;
  color: $gray;

  @include respond-to('ultrawide') {
    width: 42.188rem !important;
    padding: 2rem 0 2rem 1.563rem !important;
  }

  @include respond-to('large') {
    width: 42.188rem !important;
    padding: 2rem 0 2rem 1.563rem !important;
  }

  @include respond-to('desktop') {
    width: 30.25rem !important;
    padding: 2rem 0 2rem 1.563rem !important;
  }

  @include respond-to('laptop') {
    width: 30.25rem !important;
    padding: 3rem 1.75rem 0.688rem 1.75rem !important;
  }

  @include respond-to('tablet-large') {
    width: 24rem !important;
    height: 25.875rem !important;
    padding: 0.688rem 0 0.688rem 0 !important;
  }

  @include respond-to('tablet') {
    width: 24rem !important;
    height: 28.438rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include respond-to('phablet') {
    width: 24rem !important;
    height: 28.438rem !important;
    padding: 0.688rem 1.563rem 0.688rem 1.563rem !important;
  }

  @include respond-to('phone-large') {
    width: 24rem !important;
    height: 28.438rem !important;
  }

  @include respond-to('phone') {
    width: 24rem !important;
    height: 28.438rem !important;
  }
}

.ta-dispensary-info-right {
  position: relative;
  border: 0.5px #E2E9EA solid;
  border-left: none;
  border-bottom: none;
  background-color: #FFFFFF;
  color: $gray;

  @include respond-to('ultrawide') {
    width: 42.188rem !important;
    padding: 2rem 0 2rem 1.563rem !important;
  }

  @include respond-to('large', ) {
    width: 42.188rem !important;
    padding: 2rem 0 2rem 1.563rem !important;
  }

  @include respond-to('desktop') {
    width: 30.25rem !important;
    padding: 2rem 0 2rem 1.563rem !important;
  }

  @include respond-to('laptop') {
    width: 30.25rem !important;
    padding: 3rem 1.75rem 0.688rem 1.75rem !important;
  }

  @include respond-to('tablet-large') {
    width: 24rem !important;
    height: 25.875rem !important;
    padding: 0.688rem 0 0.688rem 0 !important;
  }

  @include respond-to('tablet') {
    width: 24rem !important;
    height: 28.438rem !important;
    display: flex;
    justify-content: center;
    vertical-align: text-top;
  }

  @include respond-to('phablet') {
    width: 24rem !important;
    height: 28.438rem !important;
    padding: 0.688rem 1.563rem 0.688rem 1.563rem !important;
  }

  @include respond-to('phone-large') {
    width: 24rem !important;
    height: 28.438rem !important;
  }

  @include respond-to('phone') {
    width: 24rem !important;
    height: 28.438rem !important;
  }
}

.ta-dispensary-logo {
  height: 99px;

  @include legacy-respond-to('laptop') {
    height: 78px;
  }
}

.ta-dispensary-name {
  font-weight: 600;
  color: $dark-green-alt !important;
}

.ta-dispensary-links {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $light-green;
  width: 100%;
  height: 4rem;
  line-height: 1rem;
  color: $white;
  font-family: $font-stack;
  font-weight: 400;
  word-wrap: break-word;
  vertical-align: top;
  cursor: pointer;
  padding-top: 1.375rem;

  @include respond-to('laptop') {
    font-size: 1rem;
    padding: 1.37rem 0 1.37rem 0;
    height: 4rem;
  }

  @include respond-to('tablet-large') {
    padding: 1.375rem 0 1.375rem 0;
    height: 4rem;
  }

  @include respond-to('tablet') {
    padding: 1.375rem 0 1.375rem 0;
    height: 4rem;
  }

  @include respond-to('phablet') {
    width: 22.5rem;
  }
}

.ta-dispensary-links2 {
  background-color: $light-green;
  color: $white;
  font-family: $font-stack;
  font-weight: 400;
  word-wrap: break-word;
  padding: 1rem 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

}

.ta-dispensary-brand-logo {
  height: 99px;
}

.ta-dispensary-link-left {
  margin-left: 2rem;
}

.ta-dispensary-links-icon {
  background-image: url("/wp-content/uploads/2024/03/ta-arrow-cir-rt.png");
  margin-left: 0.5rem;
  width: 1.25rem !important;
  height: 1.25rem !important;
  display: inline-flex;

  &:hover {
    background-image: url("/wp-content/uploads/2024/03/ta-arrow-cir-rt-hover.png");
  }
}

.ta-dispensary-info-h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;

  @include respond-to('desktop') {
    font-size: 1.5rem;
  }

  @include respond-to('laptop') {
    font-size: 1.5rem;
  }

  @include respond-to('tablet-large') {
    font-size: 1.5rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    margin-bottom: 0.5rem;
  }

  @include respond-to('tablet') {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  @include respond-to('phablet') {
    font-size: 1.5rem;
  }

  @include respond-to('phone-large') {
    font-size: 1.5rem;
  }

  @include respond-to('phone') {
    font-size: 1.5rem;
  }
}

.ta-dispensary-info-h3 {
  color: #606060;
  font-size: 1rem;
  font-weight: 600;

  @include respond-to('laptop') {
    font-size: 1rem;
  }

  @include respond-to('tablet-large') {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
}

.ta-dispensary-info-p {
  padding-right: 1.563rem;
  color: #606060;

  @include respond-to('laptop') {
    font-size: 1rem;
    padding-right: 0;
  }

  @include respond-to('tablet-large') {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }

  @include respond-to('tablet') {
    padding-right: 0;
  }

  @include respond-to('phablet') {
    padding-right: 0;
  }

  @include respond-to('phone-large') {
    padding-right: 0;
  }

  @include respond-to('phone') {
    padding-right: 0;
  }
}

.ta-dispensary-about-p {
  margin-bottom: 1.5rem !important;
}

.ta-dispensary-photo {
  padding: 0 !important;
}

.ta-dispensary-photo-large {
  max-width: 675px;

  @include respond-to('desktop') {
    display: none;
  }

  @include respond-to('laptop') {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  @include respond-to('tablet-large') {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  @include respond-to('tablet') {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  @include legacy-respond-to('phablet') {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  // @include respond-to('phablet') {
  //   display: none;
  // }
}

.ta-dispensary-photo-small {
  display: none;

  @include respond-to('desktop') {
    display: block;
    width: 30.25rem;
  }

  @include respond-to('laptop') {
    display: none;
    max-width: 30.25rem;
    max-height: 25.688rem;
    object-fit: cover;
  }

  @include respond-to('tablet-large') {
    display: none;
  }

  @include respond-to('tablet') {
    display: none;
  }

  @include respond-to('phablet') {
    display: none;
  }

  @include respond-to('phone-large') {
    display: none;
  }

  @include respond-to('phone') {
    display: none;
  }
}

.ta-dispensary-photo-xs {
  display: none;

  @include respond-to('tablet-large') {
    display: none;
    width: 24rem !important;
    height: 25.875rem !important;
    object-fit: cover;
  }

  @include respond-to('tablet') {
    display: none;
    width: 24rem;
    height: 28.438rem;
    object-fit: cover;
  }

  @include respond-to('phablet') {
    display: none;
    width: 24rem;
    height: 28.438rem;
  }

  @include respond-to('phone-large') {
    display: none;
    width: 24rem;
    height: 28.438rem;
  }

  @include respond-to('phone') {
    display: none;
    width: 24rem;
    height: 28.438rem;
  }
}

.ta-career-container {
  margin: 3rem 10.188rem 3rem 10.313rem;
}

.link:hover {
  .link-arrow {
    display: none;
  }

  .link-arrow-hover {
    display: block;
  }

  .ta-social-media {
    display: none;
  }

  .ta-social-media-hover {
    display: block
  }
}

.link-arrow {
  height: 20px;
  width: 20px;
  display: block;
}


.link-arrow-hover {
  height: 20px;
  width: 20px;
  display: none;
}

.link-underline-hover:hover {
  text-decoration: underline;
}

.ta-modal {
  .child-groups {
    @include legacy-respond-to('phablet') {
      width: 100%;
    }
  }

  .child-group-title {
    color: #2B2356;

    /* Medium/H6 Heading */
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: no-wrap;
    text-transform: none;
    ;
  }

  h4 {

    /* Medium/H5 Heading */
    font-family: $font-stack;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .horizontal-line {
    height: 0.5px;
    width: 100%;
    max-width: 576px;
    margin: auto;
  }

  .link-underline-hover:hover {
    text-decoration: underline;
  }

  .more-than-5-stores {
    @include respond-to('phablet') {
      columns: 2;
      column-gap: 3rem;
    }

    @include respond-to('phone-large') {
      columns: 2;
      column-gap: 3rem;
    }

    @include respond-to('phone') {
      columns: 2;
      column-gap: 3rem;
    }
  }

  margin: auto !important;

  .cta-link {
    color: #FFF;
    /* Semi-Bold/H6 Heading */
    font-size: 16px;
    font-family: $font-stack;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
      height: 25px;
      width: 25px;

      @include legacy-respond-to('laptop') {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.ta-modal-dialog {
  max-width: 700px !important;
  width: 700px !important;

  @include legacy-respond-to('phablet') {
    max-width: 381px !important;
    width: 381px
  }
}

.gap-3_5 {
  gap: 2rem;
}

.z2000 {
  z-index: 2000;
}

.ta-mobile-menu-container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
}

.ta-mobile-menu {
  background-color: $light-green-bg-2;
  min-width: 380px;

  .top-level-link {
    color: #124B4C;
    /* Semi-Bold/H6 Heading */
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .top-level-li {
    border-bottom: 0.5px solid #B4C5C6;
  }

  .top-level-li:last-child {
    border-bottom: none;
  }

  .top-level-link:hover {
    text-decoration: underline;
  }

  .sub-level-link {
    color: #124B4C;
    /* Medium/H6 Heading */
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    list-style: none;
  }

  .sub-level-link:hover {
    text-decoration: underline;
  }
}

#navbarNavDarkDropdown {
  .stock-container {
    background: #124B4C;
  }

  .stock-ticker {
    color: #FFF;
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .stock-price {
    color: #FFF;
    font-family: $font-stack;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.min-h-screen {
  min-height: 100vh;
}

.btn:active {
  border: none;
}

/* ==============
Page Contact Content
============== */
#page-contact-content {
  .container {
    max-width: 1320px;
    margin: 2rem auto;
    padding: 1rem 2rem;

    @include respond-to('tablet-large') {
      margin: 0 auto;
      padding: 0 2rem;
    }
  }
}

/* ==============
Gravity Form
============== */
.gform_wrapper {
  padding: 2rem 1rem;

  .gform_fields {

    input,
    select,
    textarea {
      border-radius: 6px;
      border-color: $gf-border;
    }

    .gfield_label {
      font-family: $font-stack;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .gfield_required {
      display: none;
      font-family: $font-stack;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $gf-required-orange;
    }

    .gfield_contains_required {
      .gform-field-label {
        &::before {
          display: inline-block;
          content: '*';
          position: relative;
          top: 0;
          left: 0;
          width: 8px;
          height: 10px;
          color: $gf-required-orange;
        }
      }
    }

    .gfield_validation_message {
      font-family: $font-stack;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $gf-required-orange;
    }

    .ginput_counter {
      margin-left: auto;
      width: max-content;
    }

    .gfield--type-consent {
      font-family: $font-stack;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      input[type='checkbox'] {
        &::before {
          color: $white;
        }
        &:checked {
          background-color: $button-green;
        }
      }
    }

    .gfield--width-half {
      @include respond-to('tablet-large') {
        grid-column: span 12;
      }
    }
  }

  .gform_footer {
    align-items: center;
    justify-content: center;

    .gform_button {
      font-family: $font-stack !important;
      font-size: 1rem !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 1.125rem !important;
      background-color: $light-green !important;
      color: $white !important;
      padding: 10px 16px !important;
      border: 1.5px solid $light-green !important;

      &:hover {
        background-color: $white !important;
        color: $light-green !important;
      }
    }
  }
}

/* ==============
Page Contact Cards
============== */
.page-contact-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  padding: 2rem 0.5rem;
  background-color: $teal;
  gap: 3rem;

  .page-card {
    width: 100%;
    max-width: 365px;
    padding: 2rem 2.5rem;
    background-color: $white;
    border-bottom: 3px solid $light-green;

    img {
      width: 70px;
      height: auto;
    }

    h3 {
      color: $gray;
      font-family: Montserrat, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
      white-space: nowrap;
      margin: 0 0 0.5rem;
    }

    p {
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      margin: 0 0 0.5rem;
    }

    address {
      margin: 0 0 0.5rem;
    }

    a {
      color: $light-green;
      margin: 0 0 0.5rem;

      span {
        display: inline-block;
        position: relative;
        right: -5px;
        top: 4px;
        background-image: url('/wp-content/themes/terrascend-child/assets/svg/link-arrow-right.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        transition: all 0.25s ease;
      }

      &:hover {
        span {
          background-image: url('/wp-content/themes/terrascend-child/assets/svg/link-arrow-right-hover.png');
        }
      }
    }
  }

}

/* ==============
Page Promotions
============== */
.page-promotions-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  border-top: 2px solid $orange;
  padding: 2rem 0;

  .page-promotion {
    padding: 1rem 0;
    margin: 1rem;
    width: 25%;

    @include respond-to('tablet-large') {
      width: 100%;
    }

    img {
      width: 100px;
      height: auto;
      margin: 0 0 1rem;
    }

    h3 {
      color: $gray;
      font-family: Montserrat, sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      white-space: nowrap;
      margin: 1rem 0;
    }

    p {
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }

    .divider {
      background: $divider;
      height: 1px;
      width: 60%;
      margin: 0 auto;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0 0 0.5rem;
        text-align: center;

        a {
          color: $light-green;

          span {
            display: inline-block;
            position: relative;
            right: -5px;
            top: 4px;
            background-image: url('/wp-content/themes/terrascend-child/assets/svg/link-arrow-right.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            transition: all 0.25s ease;
          }

          &:hover {
            span {
              background-image: url('/wp-content/themes/terrascend-child/assets/svg/link-arrow-right-hover.png');
            }
          }
        }
      }
    }
  }
}

.age-gate {
  width: 600px;
  height: 442px;
  background-image: url('/wp-content/uploads/2024/04/age-gate-background-600.png');
  max-width: 600px !important;
  max-height: 442px !important;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.age-gate__heading {
  padding-top: 40px;
}

.age-gate__heading-title--logo {
  padding-top: 10px;
}

.age-gate__headline {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #FFFFFF;
  padding-top: 20px;
}

.age-gate__subheadline {
  font-size: 20px !important;
  max-width: 446px;
}

.age-gate__remember {
  display: none;
}

.age-gate__submit {
  width: 77px;
  height: 38px;
  background-color: $cultivation-link !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 6px !important;
}

.age-gate__submit:hover {
  background-color: $white !important;
  border: 1.5px solid $cultivation-link !important;
  color: $cultivation-link !important;
}

.age-gate__challenge {
  padding-bottom: 25px;
}

.age-gate__challenge a {
  font-weight: 700;
}

.age-gate__buttons {
  padding-top: 20px;
  padding-bottom: 23px;
}

.age-gate__submit--yes {
  margin-right: 24px !important;
}

.otnotice-content {
  font-family: Montserrat, sans-serif !important;
}

.otnotice-sections {
  margin: 0 auto 0 auto !important;
}

.otnotice-language-dropdown-container {
  display: none;
}

.otnotice-section-header {
  color: #000000 !important;
}

.ta-404 {
  text-align: center;
  color: $white;
}

.ta-404 h1 {
  font-size: 10rem !important;
}

.ta-404 h3 {
  font-size: 2.5rem !important;
}